function DeliveryType({ deliveryType, color }) {
	return (
		<>
			{deliveryType == "delivery" && (
				<svg
					width="32px"
					height="32px"
					viewBox="0 0 48 48"
					xmlns="http://www.w3.org/2000/svg"
				>
					<g id="Layer_2" data-name="Layer 2">
						<g id="invisible_box" data-name="invisible box">
							<rect width="48" height="48" fill="none" />
						</g>
						<g id="Layer_7" data-name="Layer 7" fill={color}>
							<path d="M35.8,11a3.2,3.2,0,0,0-2.2-1H32V8a2.9,2.9,0,0,0-3-3H5A2.9,2.9,0,0,0,2,8V35a2.9,2.9,0,0,0,3,3H7.3a7,7,0,0,0,13.4,0h6.6a7,7,0,0,0,13.4,0H43a2.9,2.9,0,0,0,3-3V22.2Zm-2.7,3,7.3,8H32V14ZM14,39a3,3,0,1,1,3-3A2.9,2.9,0,0,1,14,39Zm20,0a3,3,0,1,1,3-3A2.9,2.9,0,0,1,34,39Z" />
						</g>
					</g>
				</svg>
			)}
			{deliveryType == "takeaway" && (
				<svg
					fill="#000000"
					width="32px"
					height="32px"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fill={color}
						d="M20,9H17V7A5,5,0,0,0,7,7V9H4L3,22H21ZM15,9H9V7a3,3,0,0,1,6,0Z"
					/>
				</svg>
			)}
			{deliveryType == "shop" && (
				<svg
					width="32px"
					height="32px"
					viewBox="0 0 24 24"
					xmlns="http://www.w3.org/2000/svg"
				>
					<title>table</title>
					<path
						fill={color}
						d="M18.76,6l2,4H3.24l2-4H18.76M20,4H4L1,10v2H3v7H5V16H19v3h2V12h2V10L20,4ZM5,14V12H19v2Z"
					/>
					<rect width="24" height="24" fill="none" />
				</svg>
			)}
		</>
	);
}

export default DeliveryType;
