function Origins({ origin, color }) {
	return (
		<>
			{origin == "website" && (
				<svg
					width="32px"
					height="32px"
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth={2}
					viewBox="0 0 24 24"
				>
					<path stroke="none" d="M0 0h24v24H0z" />
					<path
						fill={color}
						d="M19.5 7A8.998 8.998 0 0012 3a8.991 8.991 0 00-7.484 4M11.5 3a16.989 16.989 0 00-1.826 4M12.5 3a16.989 16.989 0 011.828 4.004M19.5 17a8.998 8.998 0 01-7.5 4 8.991 8.991 0 01-7.484-4M11.5 21a16.989 16.989 0 01-1.826-4M12.5 21a16.989 16.989 0 001.828-4.004M2 10l1 4 1.5-4L6 14l1-4M17 10l1 4 1.5-4 1.5 4 1-4M9.5 10l1 4 1.5-4 1.5 4 1-4"
					/>
				</svg>
			)}
			{origin == "pos" && (
				<svg viewBox="0 0 24 24" fill="currentColor" width="32px" height="32px">
					<path fill="none" d="M0 0h24v24H0z" />
					<path
						fill={color}
						d="M20 22H4a1 1 0 01-1-1V3a1 1 0 011-1h16a1 1 0 011 1v18a1 1 0 01-1 1zM8 9v2h8V9H8zm0 4v2h8v-2H8z"
					/>
				</svg>
			)}
			{origin == "selfordering" && (
				<svg viewBox="0 0 20 20" fill="currentColor" width="32px" height="32px">
					<path
						fill={color}
						d="M2 2c0-1.1.9-2 2-2h12a2 2 0 012 2v16a2 2 0 01-2 2H4a2 2 0 01-2-2V2zm2 0v14h12V2H4zm6 17a1 1 0 100-2 1 1 0 000 2z"
					/>
				</svg>
			)}
			{origin == "app" && (
				<svg viewBox="0 0 580 1000" fill="currentColor" width="32px" height="32px">
					<path
						fill={color}
						d="M480 10c28 0 51.667 9.667 71 29s29 43 29 71v780c0 26.667-9.667 50-29 70s-43 30-71 30H100c-26.667 0-50-10-70-30S0 916.667 0 890V110c0-28 10-51.667 30-71s43.333-29 70-29h380M290 950c20 0 36.667-5 50-15 13.333-10 20-21.667 20-35 0-14.667-6.667-26.667-20-36-13.333-9.333-30-14-50-14-18.667 0-35 5-49 15s-21 21.667-21 35c0 13.333 7 25 21 35s30.333 15 49 15m210-150V140H80v660h420"
					/>
				</svg>
			)}
			<div className="small">{origin}</div>
		</>
	);
}

export default Origins;
