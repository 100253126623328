import { useEffect, useState } from "react";
import "./calendar.css";
import moment from "moment/moment";

function Calendar({ value = moment().format("YYYY-MM-DD HH:mm:ss"), onSelect }) {
	const [daysArr, setDaysArr] = useState([]);
	const [year, setYear] = useState(2023);
	const [month, setMonth] = useState(0);
	const [selectedYear, setSelectedYear] = useState(2023);
	const [selectedMonth, setSelectedMonth] = useState(0);
	const [selectedDay, setSelectedDay] = useState(1);

	const months = [
		"Gennaio",
		"Febbraio",
		"Marzo",
		"Aprile",
		"Maggio",
		"Giugno",
		"Luglio",
		"Agosto",
		"Settembre",
		"Ottobre",
		"Novembre",
		"Dicembre",
	];
	const days = ["Lun", "Mar", "Mer", "Gio", "Ven", "Sab", "Dom"];

	useEffect(() => {
		console.log("init");
		if (!value) value = moment().format("YYYY-MM-DD HH:mm:ss");
		const d = moment(value, "YYYY-MM-DD HH:mm:ss");
		console.log(value);
		console.log(d.format("YYYY-MM-DD HH:mm:ss"));
		console.log(d.format("M"));
		console.log(parseInt(d.format("M")));
		setYear(parseInt(d.format("YYYY")));
		setMonth(parseInt(d.format("M")) - 1);
		setSelectedYear(parseInt(d.format("YYYY")));
		setSelectedMonth(parseInt(d.format("M")) - 1);
		setSelectedDay(parseInt(d.format("D")));
	}, []);

	useEffect(() => {
		const monthShift = getMonthShift(year, month + 1);
		let d = [];
		for (let i = 1; i < monthShift; i++) d.push(undefined);
		for (let i = 1; i <= getDaysInMonth(year, month + 1); i++) {
			d.push(i);
		}
		console.log(d);
		setDaysArr(d);
	}, [year, month]);

	function getDaysInMonth(year, month) {
		return new Date(year, month, 0).getDate();
	}

	function getMonthShift(year, month) {
		const d = moment(year + "-" + month + "-01", "YYYY-MM-DD");
		const shift = d.format("E");
		console.log("shift", shift);
		return shift;
	}

	const decrementMonth = () => {
		if (month > 0) setMonth(month - 1);
		else {
			setMonth(11);
			setYear(year - 1);
		}
	};

	const incrementMonth = () => {
		if (month < 11) setMonth(month + 1);
		else {
			setMonth(0);
			setYear(year + 1);
		}
	};

	return (
		<div className="calendar">
			<div className="calendar-header">
				<div className="calendar-plus-minus">
					<button onClick={() => setYear(year - 1)}>-</button>
					<div className="calendar-plus-minus-label">{year}</div>
					<button onClick={() => setYear(year + 1)}>+</button>
				</div>
				<div className="calendar-plus-minus">
					<button onClick={decrementMonth}>-</button>
					<div className="calendar-plus-minus-label">{months[month]}</div>
					<button onClick={incrementMonth}>+</button>
				</div>
			</div>

			<div className="calendar-days">
				{days.map((d, i) => (
					<div key={i}>{d}</div>
				))}
				{daysArr.map((d, i) => (
					<button
						key={i}
						className={
							selectedYear == year && selectedMonth == month && selectedDay == d
								? "selected"
								: ""
						}
						onClick={() => {
							setSelectedYear(year);
							setSelectedMonth(month);
							setSelectedDay(d);
							onSelect(year + "-" + (month + 1) + "-" + d);
						}}
					>
						{d}
					</button>
				))}
			</div>
		</div>
	);
}

export default Calendar;
