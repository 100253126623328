import { useContext, useEffect, useState } from "react";
import PopUp from "./PopUp";
import UserIcon from "../icons/UserIcon";
import { AppContext } from "../App";
import functions from "../functions/functions";
import moment from "moment/moment";
import "moment/locale/it";
import Dish from "../icons/Dish";
import api from "./api/api";
import MergeIcon from "../icons/MergeIcon";
import PlanIcon from "../icons/PlanIcon";
import SidebarContent from "./SidebarContent";
import TrashIcon from "../icons/TrashIcon";
moment.locale("it");

function TablesList() {
	const { tables, rooms, shop, setLoading, setTablesListVisible, table, setTable } =
		useContext(AppContext);
	const [room, setRoom] = useState(rooms.length > 0 ? rooms[0] : false);
	const [editMode, setEditMode] = useState(false);
	const [selectionEnabled, setSelectionEnabled] = useState(false);
	const [selectionType, setSelectionType] = useState(false);
	const [mapMode, setMapMode] = useState(
		localStorage.getItem("pos-map-mode") ? localStorage.getItem("pos-map-mode") == "true" : true
	);
	const [selectedTables, setSelectedTables] = useState([]);

	useEffect(() => {
		if (!selectionEnabled && selectedTables.length > 1) {
			if (selectionType == "merge_tables") mergeTables(selectedTables);
			if (selectionType == "parent_tables") parentTables(selectedTables);
			setSelectionType(false);
		}
		setSelectedTables([]);
	}, [selectionEnabled]);

	useEffect(() => {
		if (table) setTable(tables.find((t) => t.id == table.id));
	}, [tables]);

	useEffect(() => {
		localStorage.setItem("pos-map-mode", mapMode);
	}, [mapMode]);

	useEffect(() => {
		setTable(false);
	}, [room]);

	const mergeTables = async () => {
		console.log("mergeTables", selectedTables);
		setLoading(true);
		const res = await api.postProtected("/tables/merge_tables/", {
			tables_ids: selectedTables,
		});
		setLoading(false);
		if (res.success == 0) alert(res.error);
	};

	const parentTables = async () => {
		console.log("parentTables", selectedTables);
		setLoading(true);
		const res = await api.postProtected("/tables/parent_tables/", {
			tables_ids: selectedTables,
		});
		setLoading(false);
		if (res.success == 0) alert(res.error);
	};

	return (
		<PopUp
			size="full"
			options={{
				onClose: setTablesListVisible,
				title: "Tavoli",
				headerButtons: (
					<>
						{mapMode && (
							<>
								{!editMode ? (
									<button onClick={() => setEditMode(true)}>Modifica tavoli</button>
								) : (
									<button onClick={() => setEditMode(false)}>Termina modifica</button>
								)}
							</>
						)}
						{!mapMode ? (
							<button onClick={() => setMapMode(true)} className="icon">
								<PlanIcon />
								Abilita vista mappa
							</button>
						) : (
							<button onClick={() => setMapMode(false)} className="icon">
								<PlanIcon />
								Abilita vista elenco
							</button>
						)}
						{selectionEnabled && selectionType == "merge_tables" ? (
							<button onClick={() => setSelectionEnabled(false)} className="icon">
								<MergeIcon /> Conferma selezione
							</button>
						) : (
							<button
								onClick={() => {
									setSelectionEnabled(true);
									setSelectionType("merge_tables");
								}}
								className="icon"
							>
								<MergeIcon /> Unisci conti
							</button>
						)}
						{selectionEnabled && selectionType == "parent_tables" ? (
							<button onClick={() => setSelectionEnabled(false)} className="icon">
								<MergeIcon /> Conferma selezione
							</button>
						) : (
							<button
								onClick={() => {
									setSelectionEnabled(true);
									setSelectionType("parent_tables");
								}}
								className="icon"
							>
								<MergeIcon /> Unisci tavoli
							</button>
						)}
					</>
				),
				content: (
					<div className="tables-list">
						<div className="rooms slim-scroll">
							{rooms.map((r, i) => (
								<button
									key={i}
									onClick={() => setRoom(r)}
									className={r.id == room.id ? "active" : ""}
								>
									{r.name}
								</button>
							))}
						</div>
						{!mapMode && (
							<ListMode
								room={room}
								selectedTables={selectedTables}
								setSelectedTables={setSelectedTables}
								selectionEnabled={selectionEnabled}
							/>
						)}
						{mapMode && (
							<MapMode2
								room={room}
								selectedTables={selectedTables}
								setSelectedTables={setSelectedTables}
								selectionEnabled={selectionEnabled}
								editMode={editMode}
							/>
						)}
					</div>
				),
			}}
		/>
	);
}

function ListMode({ room, selectedTables, setSelectedTables, selectionEnabled }) {
	const { tables, shop, table, setTable, setLoading, setConfirm } = useContext(AppContext);

	const handleSelection = (tableId) => {
		const index = selectedTables.indexOf(tableId);
		if (index > -1) {
			const data = [...selectedTables];
			data.splice(index, 1);
			setSelectedTables(data);
		} else {
			setSelectedTables([...selectedTables, tableId]);
		}
	};

	const getClassName = (t, className = "") => {
		if (t.id == table.id) className += " visible";
		if (t.status == 1) className += " open";
		if (t.status == 2) className += " warning";
		if (selectedTables.includes(t.id)) className += " selected";
		return className;
	};

	const getDuration = (dateStart) => {
		const ds = moment(dateStart, "YYYY-MM-DD HH:mm:ss");
		const de = moment();
		const diff = moment.duration(ds.diff(de));
		return diff.humanize(true);
	};

	const unparentTable = async (table) => {
		console.log("unparentTable");
		setConfirm({
			title: "Sei sicuro?",
			message: "Il tavolo " + table.name + " verrà separato",
			onConfirm: async () => {
				setLoading(true);
				const res = await api.postProtected("/tables/edit/" + table.id + "/", {
					parent_id: null,
				});
				setLoading(false);
				if (res.success == 0) alert(res.error);
			},
		});
	};

	return (
		<div className="tables-sidebar-container tables-sidebar">
			<div className="tables">
				{tables
					.filter((t) => t.shop_id == shop.id && t.room_id == room.id)
					.map((table, i) => (
						<button
							key={i}
							onClick={() => {
								if (selectionEnabled) handleSelection(table.id);
								else setTable(table);
							}}
							className={getClassName(table)}
						>
							<div>
								<div className="name">
									{table.name}

									{table.childs.map((child, n) => (
										<span key={n}>
											{" + " + child.name}
											<button
												onClick={() => unparentTable(child)}
												className="inline-button"
											>
												<TrashIcon />
											</button>
										</span>
									))}
								</div>
								<div className="pin-table">
									{"Listino: " + (table.list_id ? table.list?.name : "Base")}
								</div>
								{table.pin && (
									<div className="pin-table">{"PIN: " + table.pin}</div>
								)}
							</div>
							<div>
								<div className="diner">
									<UserIcon />
									Coperti: {table.covers}/{table.availables_covers}
								</div>
								<div className="flow">
									<Dish />
									{table.flow && (
										<div>{"Ordini: " + (table.orders_count || 0)}</div>
									)}
								</div>
							</div>
							<div className="inline-total">
								{functions.formatter.format(table.orders_total)}
							</div>
							{table.status != 0 && table.date_open && (
								<div className="badge">{getDuration(table.date_open)}</div>
							)}
						</button>
					))}
			</div>
			<SidebarContent />
		</div>
	);
}

function MapMode({ room, selectedTables, setSelectedTables, selectionEnabled, editMode }) {
	const { tables, rooms, shop, setTablePopup, table, setTable, editTable } =
		useContext(AppContext);
	const sizes = {
		1: {
			width: "8%",
			height: "10%",
		},
		2: {
			width: "12%",
			height: "15%",
		},
		4: {
			width: "15%",
			height: "12%",
			color: "#fff",
		},
		6: {
			width: "18%",
			height: "16%",
		},
		8: {
			width: "21%",
			height: "20%",
		},
		10: {
			width: "24%",
			height: "20%",
		},
	};

	function startDrag(e, id) {
		if (!editMode) return;
		e.preventDefault();
		const container = document.querySelector(".tables-board");
		const el = e.target.closest(".table-container");
		console.log("startDrag", e.target, el);
		document.onmousemove = (e) => drag(e, container, el);
		document.onmouseup = (e) => stopDrag(e, el, id);
	}

	function stopDrag(e, el, id) {
		console.log("stopDrag", el, id);
		e.preventDefault();
		document.onmousemove = null;
		document.onmouseup = null;
		console.log(el.style.top, el.style.left);
		editTable(id, { pos_y: el.style.top, pos_x: el.style.left });
	}

	function drag(e, container, el) {
		e.preventDefault();
		let newY =
			e.clientY - container.offsetTop + document.querySelector(".inner-content").scrollTop;
		let newX = e.clientX - container.offsetLeft;
		if (newY <= 0) newY = 0;
		if (newX <= 0) newX = 0;
		if (newY + el.offsetHeight >= container.offsetHeight)
			newY = container.offsetHeight - el.offsetHeight;
		if (newX + el.offsetWidth >= container.offsetWidth)
			newX = container.offsetWidth - el.offsetWidth;
		const yPerc = (newY * 100) / container.offsetHeight;
		const xPerc = (newX * 100) / container.offsetWidth;
		el.style.top = yPerc + "%";
		el.style.left = xPerc + "%";
	}

	function getTableWidth(size) {
		if (size == 10) return sizes[10].height;
		if (size == 8) return sizes[8].width;
		if (size == 6) return sizes[6].width;
		if (size == 4) return sizes[4].width;
		if (size == 2) return sizes[2].width;
		if (size == 1) return sizes[1].width;
		return sizes[1].width;
	}

	const handleSelection = (tableId) => {
		const index = selectedTables.indexOf(tableId);
		if (index > -1) {
			const data = [...selectedTables];
			data.splice(index, 1);
			setSelectedTables(data);
		} else {
			setSelectedTables([...selectedTables, tableId]);
		}
	};

	const getClassName = (t, className = "") => {
		if (t.id == table.id) className += " visible";
		if (t.status == 1) className += " open";
		if (t.status == 2) className += " warning";
		if (selectedTables.includes(t.id)) className += " selected";
		return className;
	};

	const getDuration = (dateStart) => {
		const ds = moment(dateStart, "YYYY-MM-DD HH:mm:ss");
		const de = moment();
		const diff = moment.duration(ds.diff(de));
		return diff.humanize(true);
	};

	return (
		<>
			{rooms
				.filter((r) => r.shop_id == shop.id)
				.map(
					(r, n) =>
						room.id == r.id && (
							<div className="tables-board" key={n}>
								{tables
									.filter((t) => t.shop_id == shop.id && t.room_id == room.id)
									.map((table, i) => (
										<div
											className="table-container"
											key={i}
											style={{
												top: table.pos_y || "10%",
												left: table.pos_x || "10%",
												width: getTableWidth(table.pos_size),
											}}
										>
											<div
												className={getClassName(table, "table")}
												onMouseDown={(e) => startDrag(e, table.id)}
												onClick={() => {
													if (!editMode) {
														if (selectionEnabled)
															handleSelection(table.id);
														else {
															setTable(table);
															setTablePopup(true);
														}
													}
												}}
											>
												<div className="header-tables">
													<div className="name">{table.name}</div>
													{table.pin && (
														<div className="pin-table">
															{"PIN: " + table.pin}
														</div>
													)}
												</div>
												<div className="diner">
													<UserIcon />
													Coperti: {table.covers}/
													{table.availables_covers}
												</div>
												<div className="flow">
													<Dish />
													{table.flow && (
														<div>
															{"Ordini: " + (table.orders_count || 0)}
														</div>
													)}
												</div>
												<div className="line-separator"></div>
												<div className="total">
													<div className="total-value">
														{functions.formatter.format(
															table.orders_total
														)}
													</div>
												</div>
												{table.status != 0 && table.date_open && (
													<div className="badge">
														{getDuration(table.date_open)}
													</div>
												)}
											</div>
											{editMode && (
												<div className="edit-panel">
													<select
														defaultValue={table.pos_size}
														onChange={(e) =>
															editTable(table.id, {
																pos_size: e.target.value,
															})
														}
													>
														<option value={1}>Size 1</option>
														<option value={2}>Size 2</option>
														<option value={4}>Size 4</option>
														<option value={6}>Size 6</option>
														<option value={8}>Size 8</option>
														<option value={10}>Size 10</option>
													</select>
												</div>
											)}
										</div>
									))}
							</div>
						)
				)}
		</>
	);
}

function MapMode2({ room, selectedTables, setSelectedTables, selectionEnabled, editMode }) {
	const { tables, rooms, shop, setTablePopup, table, setTable, editTable } =
		useContext(AppContext);
	const sizes = {
		1: {
			width: "8%",
			height: "10%",
		},
		2: {
			width: "12%",
			height: "14%",
		},
		4: {
			width: "16%",
			height: "12%",
			color: "#fff",
		},
		6: {
			width: "18%",
			height: "16%",
		},
		8: {
			width: "22%",
			height: "20%",
		},
		10: {
			width: "24%",
			height: "20%",
		},
	};

	function startDrag(e, id) {
		if (!editMode) return;
		e.preventDefault();
		const container = document.querySelector(".tables-board");
		const el = e.target.closest(".table-container");
		console.log("startDrag", e.target, el);
		const point = document.createElement("div");
		point.classList.add("snap-point");
		container.appendChild(point);
		document.onmousemove = (e) => drag(e, container, el, point);
		document.onmouseup = (e) => stopDrag(e, el, id, point);
	}

	function stopDrag(e, el, id, point) {
		console.log("stopDrag", el, id);
		e.preventDefault();
		point.remove();
		document.onmousemove = null;
		document.onmouseup = null;
		console.log(el.style.top, el.style.left);
		snap(el);
		console.log(el.style.top, el.style.left);
		editTable(id, { pos_y: el.style.top, pos_x: el.style.left });
	}

	function drag(e, container, el, point) {
		e.preventDefault();
		let newY =
			e.clientY - container.offsetTop + document.querySelector(".inner-content").scrollTop;
		let newX = e.clientX - container.offsetLeft;
		if (newY <= 0) newY = 0;
		if (newX <= 0) newX = 0;
		if (newY + el.offsetHeight >= container.offsetHeight)
			newY = container.offsetHeight - el.offsetHeight;
		if (newX + el.offsetWidth >= container.offsetWidth)
			newX = container.offsetWidth - el.offsetWidth;
		const yPerc = (newY * 100) / container.offsetHeight;
		const xPerc = (newX * 100) / container.offsetWidth;
		el.style.top = yPerc + "%";
		el.style.left = xPerc + "%";
		drowSnapPoint(el, point);
	}

	function snap(el) {
		const top = parseFloat(el.style.top);
		const left = parseFloat(el.style.left);
		const newTop = 2 * Math.round(top / 2);
		const newLeft = 2 * Math.round(left / 2);
		console.log(newTop, newLeft);
		el.style.top = newTop + "%";
		el.style.left = newLeft + "%";
	}

	function drowSnapPoint(el, point) {
		const top = parseFloat(el.style.top);
		const left = parseFloat(el.style.left);
		const newTop = 2 * Math.round(top / 2);
		const newLeft = 2 * Math.round(left / 2);
		point.style.top = newTop + "%";
		point.style.left = newLeft + "%";
	}

	function getTableWidth(size) {
		if (size == 10) return sizes[10].height;
		if (size == 8) return sizes[8].width;
		if (size == 6) return sizes[6].width;
		if (size == 4) return sizes[4].width;
		if (size == 2) return sizes[2].width;
		if (size == 1) return sizes[1].width;
		return sizes[1].width;
	}

	const handleSelection = (tableId) => {
		const index = selectedTables.indexOf(tableId);
		if (index > -1) {
			const data = [...selectedTables];
			data.splice(index, 1);
			setSelectedTables(data);
		} else {
			setSelectedTables([...selectedTables, tableId]);
		}
	};

	const getClassName = (t, className = "") => {
		if (t.id == table.id) className += " visible";
		if (t.status == 1) className += " open";
		if (t.status == 2) className += " warning";
		if (selectedTables.includes(t.id)) className += " selected";
		return className;
	};

	const getDuration = (dateStart) => {
		const ds = moment(dateStart, "YYYY-MM-DD HH:mm:ss");
		const de = moment();
		const diff = moment.duration(ds.diff(de));
		return diff.humanize(true);
	};

	return (
		<>
			{rooms
				.filter((r) => r.shop_id == shop.id)
				.map(
					(r, n) =>
						room.id == r.id && (
							<div className="tables-board" key={n}>
								{tables
									.filter((t) => t.shop_id == shop.id && t.room_id == room.id)
									.map((table, i) => (
										<div
											className="table-container"
											key={i}
											style={{
												top: table.pos_y || "10%",
												left: table.pos_x || "10%",
												width: getTableWidth(table.pos_size),
											}}
										>
											<div
												className={getClassName(table, "table")}
												onMouseDown={(e) => startDrag(e, table.id)}
												onClick={() => {
													if (!editMode) {
														if (selectionEnabled)
															handleSelection(table.id);
														else {
															setTable(table);
															setTablePopup(true);
														}
													}
												}}
											>
												<div className="header-tables">
													<div className="name">{table.name}</div>
													{table.pin && (
														<div className="pin-table">
															{"PIN: " + table.pin}
														</div>
													)}
												</div>
												<div className="diner">
													<UserIcon />
													Coperti: {table.covers}/
													{table.availables_covers}
												</div>
												<div className="flow">
													<Dish />
													{table.flow && (
														<div>
															{"Ordini: " + (table.orders_count || 0)}
														</div>
													)}
												</div>
												<div className="line-separator"></div>
												<div className="total">
													<div className="total-value">
														{functions.formatter.format(
															table.orders_total
														)}
													</div>
												</div>
												{table.status != 0 && table.date_open && (
													<div className="badge">
														{getDuration(table.date_open)}
													</div>
												)}
											</div>
											{editMode && (
												<div className="edit-panel">
													<select
														defaultValue={table.pos_size}
														onChange={(e) =>
															editTable(table.id, {
																pos_size: e.target.value,
															})
														}
													>
														<option value={1}>Size 1</option>
														<option value={2}>Size 2</option>
														<option value={4}>Size 4</option>
														<option value={6}>Size 6</option>
														<option value={8}>Size 8</option>
														<option value={10}>Size 10</option>
													</select>
												</div>
											)}
										</div>
									))}
							</div>
						)
				)}
		</>
	);
}

export default TablesList;
