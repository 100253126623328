import * as React from "react";

function SplitIcon(props) {
	return (
		<svg
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M16 3h5v5M8 3H3v5" />
      <path d="M12 22v-8.3a4 4 0 00-1.172-2.872L3 3M15 9l6-6" />
    </svg>
	);
}

export default SplitIcon;
