import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import Calendar from "./calendar";
import List from "./List";
import moment from "moment/moment";
import api from "./api/api";
import Takeaway from "../icons/Takeaway";
import Delivery from "../icons/Delivery";

function DatePopUp() {
	const { cart, shop, editCart2, setPopup, lockEdit } = useContext(AppContext);
	const [orderDate, setOrderDate] = useState(false);
	const [deliveyTimes, setDeliveyTimes] = useState([]);

	useEffect(() => {
		if (cart.delivery_date)
			setOrderDate(moment(cart.delivery_date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"));
		else setOrderDate(moment().format("YYYY-MM-DD"));
	}, []);

	useEffect(() => {
		if (orderDate) generateDeliveyTimes();
	}, [orderDate]);

	const generateDeliveyTimes = async () => {
		console.log("generateDeliveyTimes");

		const dl = lockEdit ? "takeaway" : cart.delivery_type.id;
		const res = await api.postProtected("/pos2/availables_delivery_times/", {
			shop_id: shop.id,
			delivery_type: dl,
			delivery_date: orderDate,
			coord: cart.address ? cart.address.coord : null,
		});
		console.log(res);
		setDeliveyTimes(res.rows);
	};

	return (
		<div className="times-container">
			<h3>Seleziona il giorno</h3>
			<Calendar value={cart.delivery_date} onSelect={setOrderDate} />
			<h3>Scegli la fascia oraria</h3>
			<List
				mode={5}
				elements={deliveyTimes}
				className="times-date"
				customElement={(c, i) => (
					<a
						key={i}
						className="list-element"
						onClick={() => {
							console.log(c);
							setPopup(false);
							const data = {
								delivery_date: c.id,
								delivery_time_range: c.delivery_time_range,
							};
							if (!lockEdit) {
								data.address = {
									...cart.address,
									polygon_id: c.polygon_id,
									delivery_price: c.delivery_price,
									delivery_free_min: c.delivery_free_min,
								};
							}
							editCart2(data);
						}}
					>
						<div className="times">{c.name}</div>
						<div className="small">
							<div className=" load-numbers">
								<Takeaway />
								{c.actual_load + "/" + c.max_load}
							</div>
							{cart.delivery_type.id == "delivery" && (
								<div className=" drivers-number">
									<Delivery />
									{c.actual_load_drivers + "/" + c.max_load_drivers}
								</div>
							)}
						</div>
					</a>
				)}
			/>
		</div>
	);
}

export default DatePopUp;
