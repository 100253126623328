import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import List from "../List";
import functions from "../../functions/functions";

export default function PaymentPopup() {
	const { shop, setPaymentPopup, total, config, cart, updateOrderFromCart, checkout, setCart } =
		useContext(AppContext);
	const [payed, setPayed] = useState(0);
	const [rest, setRest] = useState(0);
	const [remaining, setRemaining] = useState(0);

	useEffect(() => {
		let t = 0;
		for (const payment of cart.payments) {
			t += parseFloat(payment.price);
		}
		setPayed(t);
		if (total > t) setRemaining(total - t);
		else setRemaining(0);
		if (t > total) setRest(t - total);
		else setRest(0);
	}, [cart]);

	const addPayment = (payment) => {
		const r = { ...cart };
		r.payments.push(payment);
		setCart(r);
	};

	const deletePayment = (i) => {
		const r = { ...cart };
		r.payments.splice(i, 1);
		setCart(r);
	};

	const isEditing = () => {
		let res = false;
		if (config.mode == "local" && cart["$loki"]) res = true;
		else if (cart.order_id) res = true;
		return res;
	};

	return (
		<div className="payment">
			<div className="payment-header">
				<div className="title">Pagamento</div>
				<button onClick={() => setPaymentPopup(false)}>&times;</button>
			</div>
			<div className="cols">
				<div className="left">
					<div className="payments-buttons">
						<List
							mode={6}
							type="payment_methods"
							elements={shop.payment_methods}
							onClick={(choiche) =>
								addPayment({ id: choiche.id, name: choiche.name, price: 10 })
							}
						/>
					</div>
					<div className="payments-keypad"></div>
				</div>
				<div className="right">
					<div className="right-header">
						<div>Importo</div>
						<div>{functions.formatter.format(total)}</div>
					</div>
					<div className="right-content">
						{cart.payments.map((payment, i) => (
							<div key={i}>
								<div>
									<button onClick={() => deletePayment(i)}>&times;</button>
									<div>{payment.name}</div>
								</div>
								<div>
									<div>{functions.formatter.format(payment.price)}</div>
								</div>
							</div>
						))}
					</div>
					<div className="right-footer">
						<div>
							<div>Da Pagare</div>
							<div>{functions.formatter.format(total)}</div>
						</div>
						<div>
							<div>Pagato</div>
							<div>{functions.formatter.format(payed)}</div>
						</div>
						{remaining > 0 && (
							<div>
								<div>Mancano</div>
								<div>{functions.formatter.format(remaining)}</div>
							</div>
						)}
						{remaining == 0 && (
							<div>
								<div>Resto</div>
								<div>{functions.formatter.format(rest)}</div>
							</div>
						)}
						{remaining == 0 && (
							<button
								onClick={() => {
									setPaymentPopup(false);
									if (isEditing()) {
										updateOrderFromCart(
											false,
											true,
											config.prebill_on_checkout == 1
										);
									} else {
										checkout(
											false,
											false,
											true,
											false,
											config.prebill_on_checkout == 1
										);
									}
								}}
							>
								Conferma
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
