import { useContext, useState } from "react";
import { AppContext } from "../../App";
import PopUp from "../PopUp";

function DiscountsPopup({ onClose }) {
	const { cart, setCart } = useContext(AppContext);
	const [value, setValue] = useState(0);
	const [isDiscount, setIsDiscount] = useState(true);
	const [type, setType] = useState("perc");

	const addDiscount = (i) => {
		let nCart = { ...cart };
		nCart.discounts.push({
			name: isDiscount ? "Sconto cassa" : "Supplemento",
			type: type,
			value_type: type,
			value: isDiscount ? value : -value,
		});
		setCart(nCart);
		onClose();
	};

	const increment = () => {
		setValue(parseInt(value) + 1);
	};

	const decrement = () => {
		if (value == 0) return;
		const val = parseInt(value) - 1;
		setValue(val);
	};

	return (
		<PopUp
			size="sm"
			options={{
				onClose: onClose,
				title: "Sconto",
				content: (
					<div className="discount">
						<div className="container">
							<div className="discount-type">
								<button
									className={isDiscount ? "selected" : ""}
									onClick={(e) => setIsDiscount(true)}
								>
									Sconto
								</button>
								<button
									className={!isDiscount ? "selected" : ""}
									onClick={(e) => setIsDiscount(false)}
								>
									Supplemento
								</button>
							</div>
							<div className="discount-type">
								<button
									className={type == "perc" ? "selected" : ""}
									onClick={(e) => setType("perc")}
								>
									%
								</button>
								<button
									className={type == "fixed" ? "selected" : ""}
									onClick={(e) => setType("fixed")}
								>
									€
								</button>
							</div>
							<h3>Inserisci il valore dello sconto che vuoi applicare</h3>
							<div className="">
								<div className="plus-minus-large">
									<div>
										<button onClick={decrement}>-</button>
									</div>
									<input
										type="text"
										value={value}
										onChange={(e) => setValue(e.target.value)}
									/>
									<button onClick={increment}>+</button>
								</div>
							</div>
						</div>
						<button className="button button-2" onClick={addDiscount}>
							Aggiungi
						</button>
					</div>
				),
			}}
		/>
	);
}

export default DiscountsPopup;
