import { useContext } from "react";
import { AppContext } from "../App";

function PopUp2({ title, onClose, children, headerButton }) {
	const { setPopup } = useContext(AppContext);
	return (
		<div className="popup">
			<div className="content">
				<div className="title">
					{title && <div>{title}</div>}
					<div className="buttons-group">
						{headerButton && (
							<button onClick={headerButton.onClick}>{headerButton.label}</button>
						)}
						<button className="close-button" onClick={onClose}>
							&times;
						</button>
					</div>
				</div>
				<div className="inner-content">{children}</div>
			</div>
		</div>
	);
}

export default PopUp2;
