import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";

export default function Updater() {
	const { setConfirm } = useContext(AppContext);
	const [updateSkipped, setUpdateSkipped] = useState(false);

	useEffect(() => {
		checkVersion();
		const versionTimeout = window.setInterval(() => checkVersion(), 5 * 60 * 1000);
		return () => clearInterval(versionTimeout);
	}, []);

	async function checkVersion() {
		const response = await fetch("/version.json", {
			method: "GET",
		});
		const json = await response.json();
		console.log("checkVersion", json.version, localStorage.getItem("pos-version"));
		if (
			localStorage.getItem("pos-version") &&
			localStorage.getItem("pos-version") != json.version
		) {
			console.log("New version available");
			if (!updateSkipped) {
				setTimeout(() => {
					setUpdateSkipped(true);
					setConfirm({
						title: "Nuova versione disponibile",
						message: "Aggiornare ora?",
						onConfirm: () => {
							window.location.reload();
						},
					});
				}, 10000);
			}
		}
		localStorage.setItem("pos-version", json.version);
	}

	return null;
}
