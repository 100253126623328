const formatter = new Intl.NumberFormat("it-IT", {
	style: "currency",
	currency: "EUR",

	// These options are needed to round to whole numbers if that's what you want.
	//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
	//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const dateMySql = (date) => {
	return date.toISOString().slice(0, 19).replace("T", " ");
};

const dateIt = (date) => {
	const options = { weekday: "long", day: "numeric", month: "short", year: "numeric" };
	return date.toLocaleDateString("it-IT", options);
};

const formatProductStatus = (status) => {
	let s = status;
	if (status == "confirmed") s = "Da preparare";
	if (status == "preparation") s = "In preparazione";
	if (status == "ready") s = "Pronto";
	if (status == "filled") s = "Completato";
	return <div className={"status-box " + status}>{s}</div>;
};

export default { formatter, dateMySql, dateIt, formatProductStatus };
