import * as React from "react";

function WaiterIcon(props) {
	return (
		<svg fill="currentColor" height="200px" width="200px" viewBox="0 0 512.003 512.003">
			<path d="M256.001,238.426c65.738,0,119.219-53.48,119.219-119.219C375.221,53.475,321.739,0,256.001,0 S136.782,53.475,136.782,119.207C136.782,184.946,190.263,238.426,256.001,238.426z"></path>
			<path d="M288.254,442.692h-64.506c-3.562,0-6.451,2.889-6.451,6.451v56.409c0,3.563,2.889,6.451,6.451,6.451h64.506 c3.562,0,6.451-2.887,6.451-6.451v-56.409C294.705,445.58,291.816,442.692,288.254,442.692z"></path>
			<path d="M289.464,256.864c-4.425-0.848-8.908-1.531-13.442-2.044c-3.803-0.43-7.12,2.592-7.12,6.42v45.738 c0,7.409-6.245,13.359-13.759,12.873c-6.857-0.444-12.043-6.444-12.043-13.315v-45.297c0-3.828-3.318-6.849-7.12-6.42 c-4.533,0.512-9.017,1.196-13.442,2.044c-3.042,0.583-5.24,3.248-5.24,6.346v147.228c0,3.562,2.889,6.451,6.451,6.451h64.506 c3.562,0,6.451-2.889,6.451-6.451V263.21C294.705,260.113,292.506,257.448,289.464,256.864z"></path>
			<path d="M182.371,269.691c-61.274,28.032-103.952,89.916-103.952,161.584v61.376c0,10.687,8.664,19.352,19.352,19.352h87.275 c3.562,0,6.451-2.887,6.451-6.451V275.571C191.496,270.879,186.637,267.739,182.371,269.691z"></path>
			<path d="M329.632,269.689c-4.266-1.951-9.125,1.19-9.125,5.882v229.98c0,3.563,2.889,6.451,6.451,6.451h87.275 c10.687,0,19.352-8.664,19.352-19.352v-61.376C433.584,359.605,390.906,297.721,329.632,269.689z"></path>
		</svg>
	);
}

export default WaiterIcon;
