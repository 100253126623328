import * as React from "react";

function Dish(props) {
	return (
		<svg
		viewBox="0 0 24 24"
		fill="currentColor"
		height="1em"
		width="1em"
		{...props}
	  >
		<path d="M21 15c0-4.625-3.507-8.441-8-8.941V4h-2v2.059c-4.493.5-8 4.316-8 8.941v2h18v-2zM2 18h20v2H2z" />
	  </svg>
	);
}

export default Dish;
