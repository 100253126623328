function FormGroup({
	type = "text",
	id,
	label,
	name,
	value,
	onChange,
	options = [],
	description,
	multiple = false,
	optionValue = "id",
	optionLabel = "name",
	childs = [],
	required = false,
}) {
	const handleChange = (e) => {
		if (multiple) {
			var options = e.target.options;
			var value = [];
			for (var i = 0, l = options.length; i < l; i++) {
				if (options[i].selected) {
					value.push(options[i].value);
				}
			}
			onChange(value);
		} else {
			onChange(e);
		}
	};

	const formatLabel = (labels, el) => {
		let str = "";
		for (const label of labels) {
			str += el[label] + " ";
		}
		return str;
	};

	if (type === "container") {
		return (
			<div className="inline-form-group">
				{childs.map((child, i) => (
					<div key={i}>{child}</div>
				))}
			</div>
		);
	}

	if (type == "spacer") return <div className="form-group"></div>;

	return (
		<div className="form-group">
			{label && <div className="label">{label + (required ? "*" : "")}:</div>}
			{type == "text" && (
				<input
					id={id}
					type="text"
					name={name}
					defaultValue={value}
					onChange={onChange}
					required={required}
				/>
			)}
			{type == "email" && (
				<input
					id={id}
					type="email"
					name={name}
					defaultValue={value}
					onChange={onChange}
					required={required}
				/>
			)}
			{type == "date" && (
				<input
					id={id}
					type="date"
					name={name}
					defaultValue={value}
					onChange={onChange}
					required={required}
				/>
			)}
			{type == "textarea" && (
				<textarea name={name} defaultValue={value} onChange={onChange}></textarea>
			)}
			{type == "checkbox" && (
				<input type="checkbox" name={name} defaultChecked={value} onChange={onChange} />
			)}
			{type == "select" && (
				<select
					name={name}
					defaultValue={value}
					onChange={handleChange}
					multiple={multiple}
				>
					{options.map((el, i) => (
						<option key={i} value={el[optionValue]}>
							{Array.isArray(optionLabel)
								? formatLabel(optionLabel, el)
								: el[optionLabel]}
						</option>
					))}
				</select>
			)}
			{description && <div className="description">{description}</div>}
		</div>
	);
}

export default FormGroup;
