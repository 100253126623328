import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import api from "./api/api";
import List from "./List";
import PopUp2 from "./PopUp2";

function AddressPopUp() {
	const { cart, editCart2, shop, setAddressPopUpVisible, setAddressFormPopUpVisible } =
		useContext(AppContext);
	const [addressesDelivery, setAddressesDelivery] = useState([]);

	useEffect(() => {
		if (cart.customer) loadAddressesDelivery();
	}, []);

	const loadAddressesDelivery = async () => {
		console.log("loadAddressesDelivery");
		const res = await api.postProtected("/pos2/addresses_delivery/", {
			customer_id: cart.customer.id,
			shop_id: shop.id,
		});
		setAddressesDelivery(res.rows);
	};

	return (
		<PopUp2
			title="Indirizzo"
			onClose={() => setAddressPopUpVisible(false)}
			headerButton={{
				label: "Aggiungi nuovo indirizzo",
				onClick: () => {
					setAddressPopUpVisible(false);
					setAddressFormPopUpVisible(true);
				},
			}}
		>
			<h3>Seleziona l'indirizzo per la consegna</h3>
			<List
				elements={addressesDelivery}
				customElement={(c, i) => (
					<div key={i} className="list-element group">
						<div>
							{c.address + " " + c.number}
							<div className="small">
								{c.zipcode + " " + c.city + " (" + c.province + ")"}
							</div>
						</div>
						<div className="buttons">
							<button
								onClick={() => {
									setAddressPopUpVisible(false);
									setAddressFormPopUpVisible(c);
								}}
							>
								Modifica
							</button>
							{c.covered == 1 && (
								<button
									onClick={() => {
										setAddressPopUpVisible(false);
										editCart2({ address: c, delivery_date: false });
									}}
								>
									Seleziona
								</button>
							)}
						</div>
					</div>
				)}
			/>
		</PopUp2>
	);
}

export default AddressPopUp;
