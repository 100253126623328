import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import PopUp2 from "./PopUp2";
import api from "./api/api";

function ReportsPopUp() {
	const { setReportsPopUpVisible, setConfirm, user, shop, config } = useContext(AppContext);
	const [reportDate, setReportDate] = useState("");

	const reportA = async (date) => {
		console.log("reportA");
		const res = await api.postProtected("/orders/report_a/", {
			thermal_printer_id: config.thermal_printer_id,
			shop_id: shop.id,
			date: date,
		});
		if (res.success == 0)
			setConfirm({
				title: "Errore",
				message: res.error,
			});
	};

	const reportB = async (date) => {
		console.log("reportB");
		const res = await api.postProtected("/orders/report_b/", {
			thermal_printer_id: config.thermal_printer_id,
			shop_id: shop.id,
			user_id: user.id,
			date: date,
		});
		if (res.success == 0)
			setConfirm({
				title: "Errore",
				message: res.error,
			});
	};

	return (
		<PopUp2 title="Report" onClose={() => setReportsPopUpVisible(false)}>
			<input type="date" value={reportDate} onChange={(e) => setReportDate(e.target.value)} />
			<button
				className="button mt-2 button-report"
				onClick={() => {
					if (reportDate) {
						reportA(reportDate);
					}
				}}
			>
				Stampa report giornata
			</button>
			<button
				className="button mt-2 button-report"
				onClick={() => {
					if (reportDate) {
						reportB(reportDate);
					}
				}}
			>
				Stampa report Utente
			</button>
		</PopUp2>
	);
}

export default ReportsPopUp;
