import { useContext } from "react";
import { AppContext } from "../../App";
import PopUp from "../PopUp";
import api from "../api/api";
import FormGroup from "../form/FormGroup";

function InvoiceFormPopup({ address, refreshAddress }) {
	const { cart, setInvoiceFormPopup, setLoading, setConfirm, editCart2 } = useContext(AppContext);

	const saveAddress = async (e) => {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData(e.target);
		const data = Object.fromEntries(formData.entries());
		const customer = await addCustomer(data);
		let endpoint = "";
		console.log(address);
		const addressReq = {
			company: data.company,
			vat: data.vat,
			sdi: data.sdi,
			pec: data.pec,
			taxcode: data.company,
			address: data.address,
			number: data.number,
			zipcode: data.zipcode,
			city: data.city,
			province: data.province,
		};
		if (address && address.id) {
			endpoint = "/addresses_billing/edit/" + address.id + "/";
		} else {
			endpoint = "/addresses_billing/add/";
			addressReq.customer_id = customer.id;
		}
		const res = await api.postProtected(endpoint, addressReq);
		setLoading(false);
		if (res.success == 1) {
			setInvoiceFormPopup(false);
			editCart2({
				customer: customer,
			});
		} else {
			setConfirm({
				title: "Errore",
				message: res.error,
			});
		}
	};

	const addCustomer = async (data) => {
		if (!cart.customer) {
			const customerReq = {
				name: data.name,
				surname: data.surname,
				mobile: data.mobile,
				email: data.email,
			};
			const res = await api.postProtected("/customers/add/", customerReq);
			if (res.success == 0) {
				setLoading(false);
				setConfirm({
					title: "Errore",
					message: res.error,
				});
			} else {
				return res.data;
			}
		} else return cart.customer;
	};

	return (
		<PopUp
			size=""
			options={{
				onClose: () => setInvoiceFormPopup(false),
				title: "Fattura",
			}}
		>
			<div className="inner-content">
				<form onSubmit={saveAddress}>
					{!cart.customer && (
						<>
							<h4>Cliente</h4>
							<div className="form">
								<FormGroup id="name" name="name" label="Nome" required={true} />
								<FormGroup
									id="surname"
									name="surname"
									label="Cognome"
									required={true}
								/>
								<FormGroup
									id="email"
									name="email"
									label="Email"
									type="email"
									required={true}
								/>
								<FormGroup
									id="mobile"
									name="mobile"
									label="Telefono"
									required={false}
								/>
							</div>
						</>
					)}
					<h4>Indirizzo</h4>
					<div className="form">
						<FormGroup
							id="company"
							name="company"
							label="Ragione Sociale"
							value={address.id ? address.company : null}
							required={true}
						/>
						<FormGroup
							id="vat"
							name="vat"
							label="P.Iva"
							value={address.id ? address.vat : null}
							required={true}
						/>
						<FormGroup
							id="sdi"
							name="sdi"
							label="Sdi"
							value={address.id ? address.sdi : null}
						/>
						<FormGroup
							id="pec"
							name="pec"
							label="Pec"
							value={address.id ? address.pec : null}
						/>
						<FormGroup
							id="taxcode"
							name="taxcode"
							label="C.F."
							value={address.id ? address.taxcode : null}
						/>
						<FormGroup
							id="address"
							name="address"
							label="Indirizzo"
							value={address ? address.address : null}
							required={true}
						/>
						<FormGroup
							id="number"
							name="number"
							label="Civico"
							value={address.id ? address.number : null}
							required={true}
						/>
						<FormGroup
							id="zipcode"
							name="zipcode"
							label="Cap"
							value={address.id ? address.zipcode : null}
							required={true}
						/>
						<FormGroup
							id="city"
							name="city"
							label="Città"
							value={address.id ? address.city : null}
							required={true}
						/>
						<FormGroup
							id="province"
							name="province"
							label="Provincia"
							value={address.id ? address.province : null}
							required={true}
						/>
					</div>
					<button className="button button-2 success mt-2">Salva</button>
				</form>
			</div>
		</PopUp>
	);
}

export default InvoiceFormPopup;
