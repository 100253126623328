import * as React from "react";

function AdvanceIcon(props) {
	return (
		    <svg fill="none" viewBox="0 0 15 15" height="1em" width="1em" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M2.146 11.146a.5.5 0 00.708.708l4-4a.5.5 0 000-.708l-4-4a.5.5 0 10-.708.708L5.793 7.5l-3.647 3.646zm6 0a.5.5 0 00.708.708l4-4a.5.5 0 000-.708l-4-4a.5.5 0 10-.708.708L11.793 7.5l-3.647 3.646z"
        clipRule="evenodd"
      />
    </svg>
	);
}

export default AdvanceIcon;
