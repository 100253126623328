import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import functions from "../functions/functions";

function VariantsPopUp({ product }) {
	const { add_product, setPopup } = useContext(AppContext);
	const [p, setP] = useState(structuredClone(product));
	const [valid, setValid] = useState(false);
	const [total, setTotal] = useState(product.price);

	useEffect(() => {
		setDefaults();
	}, []);

	useEffect(() => {
		validate();
		getTotal();
	}, [p]);

	const setDefaults = () => {
		console.log("setDefaults");
		let newP = structuredClone(p);
		newP.variants_categories.forEach((variants_category) => {
			variants_category.variants2.forEach((variant) => {
				if (variant.is_default > 0) variant.qty = 1;
			});
			variants_category.selected = countSelected(variants_category);
		});
		console.log(newP);
		setP(newP);
	};

	const increment = (type, i, n) => {
		console.log("increment");
		let newP = structuredClone(p);
		const qty = newP.variants_categories[i][type][n].qty
			? newP.variants_categories[i][type][n].qty
			: 0;
		newP.variants_categories[i][type][n].qty = qty + 1;
		newP.variants_categories[i].selected = countSelected(newP.variants_categories[i]);
		setP(newP);
	};

	const decrement = (type, i, n) => {
		console.log("decrement");
		let newP = structuredClone(p);
		const qty = newP.variants_categories[i][type][n].qty
			? newP.variants_categories[i][type][n].qty
			: 0;
		if (qty > 0) {
			newP.variants_categories[i][type][n].qty = qty - 1;
			newP.variants_categories[i].selected = countSelected(newP.variants_categories[i]);
			setP(newP);
		}
	};

	const countSelected = (vc) => {
		console.log("countSelected");
		let tot = 0;
		for (const v of vc.variants) {
			tot += v.qty ? v.qty : 0;
		}
		for (const v of vc.variants2) {
			tot += v.qty ? v.qty : 0;
		}
		for (const v of vc.products) {
			tot += v.qty ? v.qty : 0;
		}
		return tot;
	};

	const validate = () => {
		console.log("validate");
		let err = false;
		for (const vc of p.variants_categories) {
			if (!vc.selected) vc.selected = 0;
			console.log(vc.name, vc.selected, vc.min, vc.selected < vc.min);
			if (vc.selected < vc.min) err = true;
		}
		console.log("err", err);
		setValid(!err);
	};

	const getTotal = () => {
		console.log("getTotal");
		let t = parseFloat(product.price);
		for (const vc of p.variants_categories) {
			for (const v of vc.variants) {
				if (v.qty) t += v.qty * v.price;
			}
			for (const v of vc.variants2) {
				if (v.qty) t += v.qty * v.price;
			}
			for (const v of vc.products) {
				if (v.qty) t += v.qty * v.price;
			}
		}
		console.log("tot", t);
		setTotal(t);
	};

	const handleIngredients = (i) => {
		let newP = structuredClone(p);
		const val = newP.ingredients[i].removed ? newP.ingredients[i].removed : false;
		newP.ingredients[i].removed = !val;
		setP(newP);
		console.log(newP.ingredients);
	};

	return (
		<>
			<div className="inner-content">
				<div className="variants-popup">
					{p.ingredients.length > 0 && (
						<div className="variants-category">
							<div className="variants-categories-title">
								<div className="bold">Ingredienti base</div>
							</div>
							<div className="list-variant-ingredient">
								{p.ingredients.map((ingredient, i) => (
									<div key={i} className="variant">
										<input
											id={"ingredient-" + i}
											type="checkbox"
											defaultChecked
											onChange={() => handleIngredients(i)}
											disabled={ingredient.removable != 1}
										/>
										<label htmlFor={"ingredient-" + i}>{ingredient.name}</label>
									</div>
								))}
							</div>
						</div>
					)}
					{p.variants_categories.map((vc, i) => (
						<div
							key={i}
							className={"variants-category" + (vc.selected < vc.min ? " error" : "")}
						>
							<div className="variants-categories-title">
								<div>
									<div className="bold">{vc.name}</div>
									<div className="small">
										{"Min: " + vc.min + " - Max: " + vc.force_mono == 0
											? vc.max
											: 1}
									</div>
								</div>
								<div className="request-tag">RICHIESTO</div>
							</div>
							<div className="variant-column">
								{vc.variants.map((v, n) => (
									<div key={n} className="variant">
										<div>{v.name}</div>
										<div className="plus-minus">
											{vc.has_price == 1 &&
												" " + functions.formatter.format(v.price)}
											<button
												onClick={() => decrement("variants", i, n)}
												className={!v.qty || v.qty == 0 ? "disabled" : ""}
											>
												-
											</button>
											<div>{v.qty ? v.qty : 0}</div>
											{!vc.selected ||
											(vc.force_mono == 0 && vc.selected < vc.max) ||
											(vc.force_mono == 1 && (!v.qty || v.qty < 1)) ? (
												<button onClick={() => increment("variants", i, n)}>
													+
												</button>
											) : (
												<button>+</button>
											)}
										</div>
									</div>
								))}
							</div>
							<div className="variant-column">
								{vc.variants2.map((v, n) => (
									<div key={n} className="variant">
										<div>{v.name}</div>
										<div className="plus-minus">
											{vc.has_price == 1 &&
												" " + functions.formatter.format(v.price)}
											<button
												onClick={() => decrement("variants2", i, n)}
												className={!v.qty || v.qty == 0 ? "disabled" : ""}
											>
												-
											</button>
											<div>{v.qty ? v.qty : 0}</div>
											{!vc.selected ||
											(vc.force_mono == 0 && vc.selected < vc.max) ||
											(vc.force_mono == 1 && (!v.qty || v.qty < 1)) ? (
												<button
													onClick={() => increment("variants2", i, n)}
												>
													+
												</button>
											) : (
												<button>+</button>
											)}
										</div>
									</div>
								))}
								{vc.products.map((v, n) => (
									<div key={n} className="variant">
										<div>{v.name}</div>
										<div className="plus-minus">
											{vc.has_price == 1 &&
												" " + functions.formatter.format(v.price)}
											<button
												onClick={() => decrement("products", i, n)}
												className={!v.qty || v.qty == 0 ? "disabled" : ""}
											>
												-
											</button>
											<div>{v.qty ? v.qty : 0}</div>
											{!vc.selected || vc.selected < vc.max ? (
												<button onClick={() => increment("products", i, n)}>
													+
												</button>
											) : (
												<button>+</button>
											)}
										</div>
									</div>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
			<div className="variants-buttons">
				{valid ? (
					<button
						className="variants-button"
						onClick={() => {
							add_product(p, total);
							setPopup(false);
						}}
					>
						Aggiungi - {functions.formatter.format(total)}
					</button>
				) : (
					<button className="variants-button error">
						Effettua tutte le selezioni necessarie - {functions.formatter.format(total)}
					</button>
				)}
			</div>
		</>
	);
}

export default VariantsPopUp;
