import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import List from "./List";
import api from "./api/api";
import CustomerForm from "./CustomerForm";
import EditIcon from "../icons/EditIcon";
import SelectIcon from "../icons/SelectIcon";

function CustomersPopUp() {
	const {
		setPopup,
		editCart2,
		setConfirm,
		cart,
		shop,
		setAddressFormPopUpVisible,
		searchAddressTerm,
		setSearchAddressTerm,
		setLoading,
		searchTerm,
		setSearchTerm,
	} = useContext(AppContext);
	const [customers, setCustomers] = useState([]);
	const [addresses, setAddresses] = useState([]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(async () => {
			console.log(searchTerm);
			if (!searchTerm) {
				setCustomers([]);
				setLoading(false);
				return false;
			}
			const res = await api.postProtected("/pos2/customers/", {
				search: searchTerm,
			});
			setLoading(false);
			setCustomers(res.rows);
		}, 1000);

		return () => clearTimeout(delayDebounceFn);
	}, [searchTerm]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(async () => {
			console.log(searchAddressTerm);
			if (!searchAddressTerm) {
				setAddresses([]);
				setLoading(false);
				return false;
			}
			const res = await api.postProtected("/pos2/customers_addresses/", {
				shop_id: shop.id,
				search: searchAddressTerm,
			});
			setLoading(false);
			setAddresses(res.rows);
		}, 1000);

		return () => clearTimeout(delayDebounceFn);
	}, [searchAddressTerm]);

	return (
		<>
			<div className="buttons-group mb-3 title-add-customer">
				<button
					className="button button-2"
					onClick={() =>
						setPopup({
							title: "Cliente",
							content: <CustomerForm />,
							onClose: () =>
								setPopup({
									title: "Cliente",
									content: <CustomersPopUp />,
								}),
						})
					}
				>
					Crea nuovo Cliente
				</button>
				<h3 className="mb-0"> oppure cerca per nome o indirizzo</h3>
			</div>
			<div className="buttons-group splitted mt-3 mb-3 serach-input">
				{cart.delivery_type.id == "delivery" && (
					<>
						<label>Cerca cliente/indirizzo</label>
						<input
							type="text"
							placeholder="Cerca"
							value={searchAddressTerm}
							onChange={(e) => setSearchAddressTerm(e.target.value)}
						/>
					</>
				)}
				{cart.delivery_type.id != "delivery" && (
					<>
						<input
							type="text"
							placeholder="Inserisci / Ricerca cliente"
							autoFocus
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
						/>
						<button
							className="button button-2"
							onClick={() => {
								if (searchTerm) {
									editCart2({
										customer: null,
										name: searchTerm,
										delivery_date: false,
									});
									setPopup(false);
								} else
									setConfirm({
										title: "Errore",
										message: "Inserire un valore nel campo di ricerca",
									});
							}}
						>
							Salva Cliente temporaneo
						</button>
					</>
				)}
			</div>
			<List
				elements={customers}
				customElement={(c, i) => (
					<div key={i} className="list-element group">
						<div className="client-info">
							<div className="client-name">{c.surname + " " + c.name}</div>
							<div className="small">{c.area_code + c.mobile + " - " + c.email}</div>
						</div>
						<div className="buttons">
							<button
								onClick={() =>
									setPopup({
										title: "Cliente",
										content: <CustomerForm customer={c} />,
									})
								}
							>
								Modifica
							</button>
							<button
								onClick={(el) => {
									if (cart.delivery_type.id == "shop") {
										editCart2({
											customer: c,
											address: false,
										});
									} else {
										editCart2({
											customer: c,
											address: false,
											delivery_date: false,
										});
									}
									setPopup(false);
								}}
							>
								Seleziona
							</button>
						</div>
					</div>
				)}
			/>
			<List
				elements={addresses}
				customElement={(c, i) => (
					<div key={i} className="list-element">
						<div className="group">
							<div>
								<div>{c.surname + " " + c.name}</div>
								<div className="small">
									{c.area_code + c.mobile + " - " + c.email}
								</div>
							</div>
							<div className="buttons">
								<button
									className="icon-button"
									onClick={() => {
										setPopup({
											title: "Cliente",
											content: <CustomerForm customer={c} />,
										});
									}}
								>
									<EditIcon> </EditIcon>
								</button>
								<button
									onClick={() => {
										setPopup(false);
										editCart2({
											customer: {
												id: c.id,
												name: c.name,
												surname: c.surname,
												email: c.email,
												area_code: c.area_code,
												mobile: c.mobile,
											},
										});
										setAddressFormPopUpVisible(true);
									}}
								>
									Nuovo indirizzo
								</button>
							</div>
						</div>
						{c.addresses.map((address, n) => (
							<div key={n} className="subgroup">
								<div>
									<div>{address.address + " " + address.number}</div>
									<div className="small">
										{address.zipcode +
											" " +
											address.city +
											" (" +
											address.province +
											")"}
									</div>
								</div>
								<div className="buttons">
									<button
										className="icon-button"
										onClick={() => {
											setPopup(false);
											setAddressFormPopUpVisible(address);
										}}
									>
										<EditIcon> </EditIcon>
									</button>
									{address.covered == 1 && (
										<button
											className="icon-button"
											onClick={() => {
												setPopup(false);
												editCart2({
													customer: {
														id: c.id,
														name: c.name,
														surname: c.surname,
														email: c.email,
														area_code: c.area_code,
														mobile: c.mobile,
													},
													address: address,
													delivery_date: false,
												});
											}}
										>
											<SelectIcon> </SelectIcon>
										</button>
									)}
									{address.covered == 0 && <button>Non coperto</button>}
								</div>
							</div>
						))}
					</div>
				)}
			/>
		</>
	);
}

export default CustomersPopUp;
