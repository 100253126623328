import { useContext } from "react";
import { AppContext } from "../App";
import SidebarContent from "./SidebarContent";

function TablePopUp() {
	const { setTablePopup } = useContext(AppContext);

	return (
		<div className="table-popup tables-sidebar">
			<div className="content">
				<div className="close-button">
					<button onClick={() => setTablePopup(false)}>&times;</button>
				</div>
				<SidebarContent />
			</div>
		</div>
	);
}

export default TablePopUp;
