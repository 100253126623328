import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import PopUp from "../PopUp";
import api from "../api/api";
import List from "../List";
import InvoiceFormPopup from "./InvoiceFormPopup";

function InvoicePopup() {
	const {
		cart,
		setInvoiceFormPopup,
		setLoading,
		setConfirm,
		setInvoicePopup,
		init_cart,
		updateOrder,
		invoiceFormPopup,
		checkout,
		config,
		editCart2,
	} = useContext(AppContext);
	const [searchTerm, setSearchTerm] = useState("");
	const [addresses, setAddresses] = useState([]);
	const [customers, setCustomers] = useState([]);

	useEffect(() => {
		refreshAddress();
	}, [cart.customer, invoiceFormPopup]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(async () => {
			console.log(searchTerm);
			if (searchTerm) {
				const res1 = await api.postProtected("/customers/list/", {
					src: [
						{
							name: "global",
							value: searchTerm,
							compare: "id,name,surname,email,mobile",
						},
						{ name: "blacklist", value: 0, compare: "equal" },
					],
				});
				setCustomers(res1.rows);
				const res2 = await api.postProtected("/addresses_billing/list/", {
					src: [
						{
							name: "global",
							value: searchTerm,
							compare: "id,company,address,city,province,vat,taxcode,sdi,pec",
						},
					],
				});
				if (res2.success == 0) alert(res2.error);
				else setAddresses(res2.rows);
			}
		}, 1000);

		return () => clearTimeout(delayDebounceFn);
	}, [searchTerm]);

	const refreshAddress = async () => {
		if (cart.customer) {
			setLoading(true);
			const res = await api.postProtected("/addresses_billing/list/", {
				src: [{ name: "customer_id", value: cart.customer.id, compare: "equal" }],
			});
			setLoading(false);
			if (res.success == 1) {
				setAddresses(res.rows);
			} else
				setConfirm({
					title: "Errore",
					message: res.error,
				});
		}
	};

	const isEditing = () => {
		//console.log("isEditing", cart);
		let res = false;
		if (config.mode == "local" && cart["$loki"]) res = true;
		else if (cart.order_id) res = true;
		//console.log("isEditing", res);
		return res;
	};

	const getCustomerById = async (customerId) => {
		const res = await api.postProtected("/customers/get/" + customerId + "/");
		if (res.success == 0) {
			setLoading(false);
			setConfirm({
				title: "Errore",
				message: res.error,
			});
		} else return res.data;
	};

	return (
		<>
			<PopUp
				size=""
				options={{
					onClose: () => setInvoicePopup(false),
					title: "Fattura",
				}}
			>
				<div className="inner-content">
					{cart.customer && (
						<div>
							<h3>Cliente selezionato</h3>
							<div className="input-group">
								{cart.customer.surname + " " + cart.customer.name}
								<br />
								{cart.customer.email}
								<button
									onClick={() => {
										setCustomers([]);
										setAddresses([]);
										editCart2({
											customer: false,
										});
									}}
								>
									Cambia
								</button>
								<button onClick={() => setInvoiceFormPopup(true)}>
									Aggiungi indirizzo
								</button>
							</div>
						</div>
					)}
					{!cart.customer && (
						<>
							<h3>
								Ricerca azienda o cliente per nome o per indirizzo oppure creane uno
								nuovo
							</h3>
							<div className="input-group">
								<input
									type="text"
									placeholder="Ricerca"
									autoFocus
									onChange={(e) => setSearchTerm(e.target.value)}
								/>
								<button onClick={() => setInvoiceFormPopup(true)}>
									Crea Nuovo
								</button>
							</div>
							{customers.length > 0 && (
								<>
									<h3>Clienti</h3>
									<List
										elements={customers}
										customElement={(c, i) => (
											<div key={i} className="list-element group">
												<div className="client-info">
													<div className="client-name">
														{c.surname + " " + c.name}
													</div>
													<div className="small">
														{c.area_code + c.mobile + " - " + c.email}
													</div>
												</div>
												<div className="buttons">
													<button
														onClick={() => {
															editCart2({
																customer: c,
															});
															setCustomers([]);
														}}
													>
														Seleziona
													</button>
												</div>
											</div>
										)}
									/>
								</>
							)}
						</>
					)}
					<h3>Indirizzi di fatturazione</h3>
					{addresses.length > 0 && (
						<>
							<List
								elements={addresses}
								customElement={(c, i) => (
									<div key={i} className="list-element group">
										<div>
											{c.company}
											<div className="small">{"P.iva " + c.vat}</div>
											<div className="small">{"Sdi " + c.sdi}</div>
											<div className="small">{"C.F. " + c.taxcode}</div>
											<div className="small">{"Pec " + c.pec}</div>
											<div className="small">
												{c.address + " " + c.number}
											</div>
											<div className="small">
												{c.zipcode + " " + c.city + " (" + c.province + ")"}
											</div>
										</div>
										<div className="buttons">
											<button
												onClick={() => {
													setInvoiceFormPopup(c);
												}}
											>
												Modifica
											</button>
											<button
												onClick={async () => {
													if (isEditing())
														setConfirm({
															title: "Sei sicuro?",
															message:
																"Procedendo l'ordine non sarà più modificabile",
															onConfirm: async () => {
																setInvoicePopup(false);
																updateOrder(
																	{
																		id: cart.order_id,
																		$loki: cart["$loki"],
																	},
																	{
																		invoice: 1,
																		billing_address: c,
																		prebill_request: 1,
																		prebill_printed: 0,
																		bill: 4,
																		payment_method_id: 4,
																	}
																);
																init_cart();
															},
														});
													else {
														checkout(
															false,
															false,
															false,
															false,
															false,
															{
																bill: 4,
																payment_method_id: 4,
																invoice: 1,
																billing_address: c,
																billing_address_id: c.id,
																prebill_request: 1,
																prebill_printed: 0,
																customer:
																	cart.customer ||
																	(await getCustomerById(
																		c.customer_id
																	)),
															}
														);
														setInvoicePopup(false);
													}
												}}
											>
												Emetti fattura
											</button>
										</div>
									</div>
								)}
							/>
						</>
					)}
				</div>
			</PopUp>
			{invoiceFormPopup && (
				<InvoiceFormPopup address={invoiceFormPopup} refreshAddress={refreshAddress} />
			)}
		</>
	);
}

export default InvoicePopup;
