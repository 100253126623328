import { useState } from "react";
import PopUp from "../PopUp";
import ChevronLeft from "../../icons/ChevronLeft";

export default function PinPad({ onSubmit, onCancel }) {
	const [pin, setPin] = useState("");
	const buttons = [
		{ label: 1 },
		{ label: 2 },
		{ label: 3 },
		{ label: 4 },
		{ label: 5 },
		{ label: 6 },
		{ label: 7 },
		{ label: 8 },
		{ label: 9 },
		{ label: "" },
		{ label: 0 },
		{ label: <ChevronLeft />, action: "cancel" },
	];

	return (
		<PopUp
			size="sm"
			options={{
				onClose: onCancel,
				title: "Autorizzazione richiesta",
			}}
		>
			<div className="inner-content">
				<div className="pin-pad">
					<p>Inserire pin amministratore</p>
					<input type="text" value={pin} readOnly />
					<div className="pin-pad-buttons">
						{buttons.map((button, i) => (
							<button
								key={i}
								onClick={() => {
									if (button.action == "cancel") setPin(pin.slice(0, -1));
									else setPin(pin + "" + button.label);
								}}
							>
								{button.label}
							</button>
						))}
					</div>
					<button
						className="submit"
						onClick={() => {
							onSubmit(pin);
							setPin("");
						}}
					>
						Conferma
					</button>
				</div>
			</div>
		</PopUp>
	);
}
