import { useContext } from "react";
import { AppContext } from "../App";
import logo from "./../images/logo.png";

function Lock() {
	const { loadingMessage, loadingError, config, setConfig } = useContext(AppContext);

	return (
		<div className="lock">
			<div className="content">
				<img src={logo} />
				<div>{loadingMessage || "Loading ..."}</div>
				{loadingError && (
					<>
						<div className="text-error">{loadingError}</div>
						{config.mode == "local" && (
							<button
								className="button"
								onClick={(e) => {
									setConfig({ ...config, mode: "remote" });
									setTimeout(() => {
										window.location.reload();
									}, 500);
								}}
							>
								Torna alla modalità cloud
							</button>
						)}
					</>
				)}
			</div>
		</div>
	);
}

export default Lock;
