import { useEffect, useState } from "react";

function List({
	elements,
	onClick,
	label = "name",
	cancelElement = false,
	customElement = false,
	mode = 1,
	className = "",
	type = "",
}) {
	const [rows, setRows] = useState([]);

	useEffect(() => {
		if (type == "payment_methods") {
			setRows(addIcons(elements));
		} else {
			setRows(elements);
		}
	}, [elements]);

	function addIcons(elements) {
		const res = [];
		for (const element of elements) {
			const icon = "/payments/" + element.id + ".png";
			res.push({ ...element, icon: icon });
		}
		return res;
	}

	return (
		<div className={"list " + className + " mode-" + mode}>
			{cancelElement && (
				<a className="list-element" onClick={() => onClick(false)}>
					{cancelElement}
				</a>
			)}
			{customElement ? (
				<>{rows && rows.map((el, i) => customElement(el, i))}</>
			) : (
				rows &&
				rows.map((el, i) => (
					<a key={i} className="list-element" onClick={() => onClick(el)}>
						{el.icon && (
							<div className="icon">
								<img src={el.icon} />
							</div>
						)}
						{el[label]}
					</a>
				))
			)}
		</div>
	);
}

export default List;
