import * as React from "react";

function InvoiceIcon(props) {
	return (
		   <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M21 11.11V7a2 2 0 00-.58-1.41A1.87 1.87 0 0019 5h-4V3a1.9 1.9 0 00-.58-1.42A1.9 1.9 0 0013 1H9a1.9 1.9 0 00-1.42.58A1.9 1.9 0 007 3v2H3a1.87 1.87 0 00-1.42.59A2 2 0 001 7v11a2 2 0 00.58 1.41A1.87 1.87 0 003 20h7.26A7 7 0 1021 11.11M9 3h4v2H9M3 18V7h16v2.68A6.84 6.84 0 0016 9a7 7 0 00-7 7 6.91 6.91 0 00.29 2M19 20a5 5 0 01-6 0 4.94 4.94 0 01-2-4 5 5 0 015-5 4.94 4.94 0 013 1 5 5 0 010 8m-4-7h1.5v2.82l2.44 1.41-.75 1.3L15 16.69V13" />
    </svg>
	);
}

export default InvoiceIcon;
