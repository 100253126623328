import * as React from "react";

function ChevronLeft(props) {
	return (
		<svg viewBox="0 0 24 24" fill="none" width="24">
			<path
				id="Vector"
				d="M15 19L8 12L15 5"
				stroke="#000000"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			></path>
		</svg>
	);
}

export default ChevronLeft;
