export default function PlusMinusLarge({ value, onChange, min = 0 }) {
	const increment = () => {
		onChange(parseInt(value) + 1);
	};

	const decrement = () => {
		if (parseInt(value) <= min) return;
		const val = parseInt(value) - 1;
		onChange(val);
	};

	return (
		<div className="plus-minus-large">
			<div>
				<button onClick={decrement}>-</button>
			</div>
			<input type="text" value={value} onChange={(e) => onChange(e.target.value)} />
			<button onClick={increment}>+</button>
		</div>
	);
}
