import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import List from "./List";
import PopUp from "./PopUp";

function TableSelector({ order }) {
	const { rooms, tables, shop, setTableSelectorVisible, updateOrder, apiSettings } =
		useContext(AppContext);
	const [room, setRoom] = useState(false);
	const [filteredRooms, setFilteredRooms] = useState([]);
	const [filteredTables, setFilteredTables] = useState([]);

	useEffect(() => {
		if (rooms) {
			setFilteredRooms(rooms.filter((e) => e.shop_id == shop.id));
		}
	}, [rooms]);

	useEffect(() => {
		if (filteredRooms.length > 0) {
			setRoom(filteredRooms[0]);
		}
	}, [filteredRooms]);

	useEffect(() => {
		if (room) {
			if (apiSettings.orders.tables_open_by_filled == 1) {
				setFilteredTables(
					tables.filter((e) => e.room_id == room.id && e.id != order.table_id)
				);
			} else {
				setFilteredTables(
					tables.filter(
						(e) => e.room_id == room.id && e.id != order.table_id && e.status == 1
					)
				);
			}
		}
	}, [room, tables]);

	return (
		<>
			<PopUp
				size="sm"
				options={{
					onClose: () => setTableSelectorVisible(false),
					title: "Ordine " + order.id,
					subtitle: "Scegli il tavolo di destinazione",
					content: (
						<>
							<div className="horizzontal-menu">
								{filteredRooms.map((r, i) => (
									<button
										key={i}
										onClick={() => setRoom(r)}
										className={r.id == room.id ? "active" : ""}
									>
										{r.name}
									</button>
								))}
							</div>
							<List
								mode={3}
								//className="table-list"
								elements={filteredTables}
								onClick={(el) => {
									console.log(el);
									setTableSelectorVisible(false);
									updateOrder(order, {
										room_id: room.id,
										table_id: el.id,
										self_cart_id: el.cart_id || null,
									});
								}}
							/>
						</>
					),
				}}
			/>
		</>
	);
}

export default TableSelector;
