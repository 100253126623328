import { useContext, useState } from "react";
import { AppContext } from "../App";
import CategoryIcon from "../icons/CategoryIcon";
import CategoryPosIcon from "../icons/CategoryPosIcon";

function Sidebar() {
	const {
		filteredCategories,
		category,
		setCategory,
		categoryPos,
		posCategories,
		mode,
		setMode,
		setCategoryPos,
	} = useContext(AppContext);
	const [menuOpen, setMenuOpen] = useState(false);

	function toggleSubmenu() {
		if (menuOpen) setMenuOpen(false);
		else setMenuOpen(true);
	}

	return (
		<div className="sidebar">
			<div className="modes-buttons">
				<button
					onClick={() => {
						if (mode == 1) toggleSubmenu();
						else setMenuOpen(true);
						setMode(1);
					}}
					className={mode == 1 ? "selected" : ""}
				>
					<div className="icon-category">
						<CategoryIcon />
					</div>
					<div className="name-category">Categorie</div>
				</button>
				<button
					onClick={() => {
						if (mode == 2) toggleSubmenu();
						else setMenuOpen(true);
						setMode(2);
					}}
					className={mode == 2 ? "selected" : ""}
				>
					<div className="icon-category">
						<CategoryPosIcon />
					</div>
					<div className="name-category">Categorie POS</div>
				</button>
			</div>
			<div className={"categories-container scroll mode-" + mode + (menuOpen ? " open" : "")}>
				{mode == 1 &&
					filteredCategories &&
					filteredCategories.map((course, i) => (
						<div key={i}>
							{course.name && <div className="course-name">{course.name}</div>}
							{course.categories.map((cat, n) => (
								<button
									key={n}
									className={
										"button button-1" +
										(cat.id == category.id ? " selected" : "")
									}
									onClick={() => {
										setCategory(cat);
										setMenuOpen(false);
									}}
								>
									{cat.name}
								</button>
							))}
						</div>
					))}
				{mode == 2 &&
					posCategories.map((category, i) => (
						<button
							key={i}
							className={
								"button button-1" +
								(category.id == categoryPos.id ? " selected" : "")
							}
							onClick={() => {
								setCategoryPos(category);
								setMenuOpen(false);
							}}
						>
							{category.name}
						</button>
					))}
			</div>
		</div>
	);
}

export default Sidebar;
