import * as React from "react";

function BarIcon(props) {
	return (
		<>

			<svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M11.992 1.966c-.434 0-.87.086-1.28.257L1.779 5.917c-.503.208-.49.908.012 1.116l8.982 3.558a3.266 3.266 0 002.454 0l8.982-3.558c.503-.196.503-.908.012-1.116l-8.957-3.694a3.255 3.255 0 00-1.272-.257zM23.4 8.056a.589.589 0 00-.222.045l-10.012 3.877a.612.612 0 00-.38.564v8.896a.6.6 0 00.821.552L23.62 18.1a.583.583 0 00.38-.551V8.653a.6.6 0 00-.6-.596zM.676 8.095a.644.644 0 00-.48.19C.086 8.396 0 8.53 0 8.69v8.355c0 .442.515.737.908.54l6.27-3.006.307-.147 2.969-1.436c.466-.22.43-.908-.061-1.092L.883 8.138a.57.57 0 00-.207-.044z" />
    </svg>
		</>
	);
}

export default BarIcon;
