import * as React from "react";

function Table(props) {
	return (
		<svg viewBox="0 0 24 24" fill="currentColor" height="1em" width="1em" {...props}>
			<path d="M4 6h16v3h-2l.22 2H23v2h-4.56l.56 5h-2.5l-.56-5H8.06l-.56 5H5l.56-5H1v-2h4.78L6 9H4m11.5 0h-7l-.21 2h7.42z" />
		</svg>
	);
}

export default Table;
