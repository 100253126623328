import * as React from "react";

function Takeaway(props) {
	return (
		<svg viewBox="0 0 512 512" fill="currentColor" height="1em" width="1em" {...props}>
			<path d="M454.65 169.4A31.82 31.82 0 00432 160h-64v-16a112 112 0 00-224 0v16H80a32 32 0 00-32 32v216c0 39 33 72 72 72h272a72.22 72.22 0 0050.48-20.55 69.48 69.48 0 0021.52-50.2V192a31.75 31.75 0 00-9.35-22.6zM176 144a80 80 0 01160 0v16H176z" />
		</svg>
	);
}

export default Takeaway;
